@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
}

body {
     overflow-x: hidden;
}

/* @media(-webkit-device-pixel-ratio: 1.25) {
     html  {
          font-size: 80%;
     }

     img {
          width: 90%;
          height: 90%;
     }
} */

/* @media(-webkit-device-pixel-ratio: 1.25) {
     html  {
          font-size: 60%;
     }

     img {
          width: 90%;
          height: 90%;
     }

     #img1 {
          width: 50%;
          height: 30%;
     }
  } */


@media(-webkit-device-pixel-ratio: 1.5) {
     html  {
          font-size: 70%;
     }

     img {
          width: 90%;
          height: 90%;
     }

     #imgKontakt {
           height: 200%; 
           padding-right: 64rem;
           top: 18rem;
     }
     #imgSlnko {
          height: 200%;
          top: -2rem;
     }

     #imgDazdovka {
          top: -14rem;
          width:65%
     }

     #imgKvetik {
          height: 20%;
          left: 37rem;
          
     }

     #divKtoSomText {
          width: 43%
     }

     /* O nas */

     #img1Natalia {
          width: 65%;
          height: 45%;
          left: -44rem;
          top: -144rem;
     }
     #imgJablko {
          top: -0rem;
          width: 30%;
          left: -50rem;
     }

     #imgHviezdicka {
          top: -118rem;
          width: 30%;
          left: -48rem;
     }

     #imgRastlinka {
          width: 30%;
          left: -28rem;
          top: -70rem
     }

     #galeriaModalDiv {
          padding-top: 7rem;
     }

     #ktosom {
          padding-top:3rem
     }

     #coponukam{
          padding-top:3rem
     }

     #galeria{
          padding-top:3rem
     }

     #imgSestuholnik{
          width: 100%;
          height: 100%
     }
     #imgVykricnik{
          width: 120%;
          height: 120%;
          top: -8rem
     }
     #imgZelenaHviezda{
          width: 120%;
          height: 120%;
          top: -15rem
     }
     #imgGalleryMotion{
          width: 15%;
          padding-top: 14rem;
      }

     /*  */
  }


  @media(-webkit-device-pixel-ratio: 1.25) {
     html  {
          font-size: 70%;
     }

     img {
          width: 90%;
          height: 90%;
     }

     #imgKontakt {
           height: 200%; 
           padding-right: 64rem;
           top: 18rem;
     }
     #imgSlnko {
          height: 200%;
          top: -2rem;
     }

     #imgDazdovka {
          top: -14rem;
          width:65%
     }

     #imgKvetik {
          height: 20%;
          left: 37rem;
          bottom: 50rem
     }

     #divKtoSomText {
          width: 43%
     }

     /* O nas */

     #img1Natalia {
          width: 85%;
          height: 60%;
          left: -44rem;
          top: -128rem;
     }
     #imgJablko {
          top: 1.5rem;
          width: 30%;
          left: -48rem;
     }

     #imgHviezdicka {
          top: -105rem;
          width: 30%;
          left: -45rem;
     }

     #imgRastlinka {
          width: 30%;
          left: -28rem;
          top: -64rem
     }

     #galeriaModalDiv {
          padding-top: 7rem;
     }

     #ktosom {
          padding-top:3rem
     }

     #coponukam{
          padding-top:3rem
     }

     #galeria{
          padding-top:3rem
     }

     #imgSestuholnik{
          width: 100%;
          height: 100%
     }
     #imgVykricnik{
          width: 120%;
          height: 120%;
          top: -14rem
     }
     #imgZelenaHviezda{
          width: 120%;
          height: 120%;
          top: -18rem
     }

     #imgGalleryMotion{
         width: 15%;
         padding-top: 14rem;
     }
    
     /*  */
  }

/**
 * @license
 *
 * Font Family: Satoshi
 * Designed by: Deni Anggara
 * URL: https://www.Fontshare.com/Fonts/satoshi
 * © 2023 Indian Type Foundry
 *
 * Font Styles:
 * Satoshi Variable(Variable font)
 * Satoshi Variable Italic(Variable font)
 * Satoshi Light
 * Satoshi Light Italic
 * Satoshi Regular
 * Satoshi Italic
 * Satoshi Medium
 * Satoshi Medium Italic
 * Satoshi Bold
 * Satoshi Bold Italic
 * Satoshi Black
 * Satoshi Black Italic
 *
*/


/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 900.0;
*
* available axes:

* 'wght' (range from 300.0 to 900.0)

*/

@font-face {
    font-family: 'Satoshi-Variable';
    src: url('./Fonts/Satoshi-Variable.woff2') format('woff2'),
         url('./Fonts/Satoshi-Variable.woff') format('woff'),
         url('./Fonts/Satoshi-Variable.ttf') format('truetype');
         font-weight: 300 900;
         font-display: swap;
         font-style: normal;
  }
  
  
  /**
  * This is a variable font
  * You can controll variable axes as shown below:
  * font-variation-settings: 'wght' 900.0;
  *
  * available axes:
  
  * 'wght' (range from 300.0 to 900.0)
  
  */
  
  @font-face {
    font-family: 'Satoshi-VariableItalic';
    src: url('./Fonts/Satoshi-VariableItalic.woff2') format('woff2'),
         url('./Fonts/Satoshi-VariableItalic.woff') format('woff'),
         url('./Fonts/Satoshi-VariableItalic.ttf') format('truetype');
         font-weight: 300 900;
         font-display: swap;
         font-style: italic;
  }
  
  
  @font-face {
    font-family: 'Satoshi-Light';
    src: url('./Fonts/Satoshi-Light.woff2') format('woff2'),
         url('./Fonts/Satoshi-Light.woff') format('woff'),
         url('./Fonts/Satoshi-Light.ttf') format('truetype');
         font-weight: 300;
         font-display: swap;
         font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Satoshi-LightItalic';
    src: url('./Fonts/Satoshi-LightItalic.woff2') format('woff2'),
         url('./Fonts/Satoshi-LightItalic.woff') format('woff'),
         url('./Fonts/Satoshi-LightItalic.ttf') format('truetype');
         font-weight: 300;
         font-display: swap;
         font-style: italic;
  }
  
  
  @font-face {
    font-family: 'Satoshi-Regular';
    src: url('./Fonts/Satoshi-Regular.woff2') format('woff2'),
         url('./Fonts/Satoshi-Regular.woff') format('woff'),
         url('./Fonts/Satoshi-Regular.ttf') format('truetype');
         font-weight: 400;
         font-display: swap;
         font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Satoshi-Italic';
    src: url('./Fonts/Satoshi-Italic.woff2') format('woff2'),
         url('./Fonts/Satoshi-Italic.woff') format('woff'),
         url('./Fonts/Satoshi-Italic.ttf') format('truetype');
         font-weight: 400;
         font-display: swap;
         font-style: italic;
  }
  
  
  @font-face {
    font-family: 'Satoshi-Medium';
    src: url('./Fonts/Satoshi-Medium.woff2') format('woff2'),
         url('./Fonts/Satoshi-Medium.woff') format('woff'),
         url('./Fonts/Satoshi-Medium.ttf') format('truetype');
         font-weight: 500;
         font-display: swap;
         font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Satoshi-MediumItalic';
    src: url('./Fonts/Satoshi-MediumItalic.woff2') format('woff2'),
         url('./Fonts/Satoshi-MediumItalic.woff') format('woff'),
         url('./Fonts/Satoshi-MediumItalic.ttf') format('truetype');
         font-weight: 500;
         font-display: swap;
         font-style: italic;
  }
  
  
  @font-face {
    font-family: 'Satoshi-Bold';
    src: url('./Fonts/Satoshi-Bold.woff2') format('woff2'),
         url('./Fonts/Satoshi-Bold.woff') format('woff'),
         url('./Fonts/Satoshi-Bold.ttf') format('truetype');
         font-weight: 700;
         font-display: swap;
         font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Satoshi-BoldItalic';
    src: url('./Fonts/Satoshi-BoldItalic.woff2') format('woff2'),
         url('./Fonts/Satoshi-BoldItalic.woff') format('woff'),
         url('./Fonts/Satoshi-BoldItalic.ttf') format('truetype');
         font-weight: 700;
         font-display: swap;
         font-style: italic;
  }
  
  
  @font-face {
    font-family: 'Satoshi-Black';
    src: url('./Fonts/Satoshi-Black.woff2') format('woff2'),
         url('./Fonts/Satoshi-Black.woff') format('woff'),
         url('./Fonts/Satoshi-Black.ttf') format('truetype');
         font-weight: 900;
         font-display: swap;
         font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Satoshi-BlackItalic';
    src: url('./Fonts/Satoshi-BlackItalic.woff2') format('woff2'),
         url('./Fonts/Satoshi-BlackItalic.woff') format('woff'),
         url('./Fonts/Satoshi-BlackItalic.ttf') format('truetype');
         font-weight: 900;
         font-display: swap;
         font-style: italic;
  }
  
  